import { Helpers } from "./classes/handlebars/Helpers";
import { Globals, ModuleExecutionStatus } from "./classes/Globals";
import { Json } from "./libs/Json";
import { Pair } from "./libs/Pair";
import { Module } from "./classes/mvc/Module";
import { Model } from "./classes/mvc/Model";
import { ModuleMenu } from "./modules/Menu/ModuleMenu";

declare let ezentrum_variables: any; // Global Var (All ezentrum-shop-vars)
declare var window: any; // Global Var (window -> General dom window)

export class Modules {
    // Public Static Variables
    public static modulesVariables: any; // Globale eZentrum Variablen
    public static isInShopView: boolean; // Status: True => im Shop, Status: False => CMS
    public static isNewSessionStucture: boolean; // Status: True => neuer Aufbau, Status: False => Altes Modulsystem bis 05.07.2021
    // Public Variables
    public modulesConfiguration: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> modules
    public modules: Array<Module<Model>>; // Global Array of all Modules
    // Privat Variables
    private globalConfigurations: Object;   // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> global
    private template: string; // Inkludiert die Konfiguration für alle Module - conf.json / conf.yaml -> z.B.: foundation
    private language: Pair<number, string>; // stellt die Sprache ein per ID
    private flagAllowCookies: boolean;  // Zeigt an, ob Cookies erlaubt sind oder nicht
    private sKontaktID: string; // Session Kontakt ID
    private sKontaktKEY: string;    // Session Kontakt Key
    private sTICKCOUNT: string; // Session Tick Count
    private configuration: any;

    // Constructor
    public constructor() {}

    // method: checkRequirements
    // returns false, if requirements are not ok.
    public async checkRequirements(): Promise<boolean> {
        this.configuration = {
            "global": {
                "mandant_id": "201",
                "prevent_special_chars_in_password_fields": true,
                "sessionVars": "",
                "sessionVars_lang": [
                    {
                        "html_lang": "de-DE",
                        "url": "/filesync/ezmodule"
                    }
                ],
                "language_mapping": {
                    "1": "de",
                    "2": "en",
                    "3": "nl",
                    "4": "es"
                },
                "livesuche_language_mapping": {
                    "de": "215",
                    "en": "215",
                    "nl": "215",
                    "es": "215"
                },
                "wpModalFix": true
            },
            "modules": {
                "Menu": {
                    "active": true,
                    "components": {
                        "foundation": {
                            "link": "{{#_if body.sbid '>' 0}}/[label:list]/{{/_if}}{{{curr_item_link}}}",
                            "templates": {
                                "header_nav": {
                                    "main_container": "{{{elements}}}\n",
                                    "containers": {
                                        "1": "<li class=\"accordion-item ez_header_lv1\">\n  <a class='btn btn-main {{#_if head.position '==' '1'}} ez_first_item{{/_if}}' id='collapse-nav-{{body.id}}' type='button' data-bs-toggle='collapse' data-bs-target='#collapsable-head-{{body.id}}' aria-expanded='false' aria-controls='collapsable-head-{{body.id}}'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n  </a>\n  <ul class='accordion-collapse collapse ez_header_left_bar' id='collapsable-head-{{body.id}}' role=\"tablist\" aria-labelledby=\"collapse-nav-{{body.id}}\" data-bs-parent=\"#ez-header-navi\">\n    {{{elements}}}\n  </ul>\n  <div class=\"ez_backdrop\"></div>\n</li>\n",
                                        "2": "{{#_if head.article_count '>' 0}}\n  <li class=\"nav-item ez_header_lv2{{#_if head.position '==' '1'}} ez_first_item{{/_if}}\" role=\"presentation\">\n    <a id='{{body.id}}-tab' class='nav-link{{#_if head.position '==' '1'}} active{{/_if}}' aria-controls=\"pane-{{body.id}}\" role='tab' type=\"button\" data-bs-toggle=\"tab\" data-bs-target=\"#pane-{{body.id}}\">\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    </a>\n    <div class='ez_right_sublevel tab-pane fade{{#_if head.position '==' '1'}} show active{{/_if}}' id='pane-{{body.id}}' role=\"tabpanel\" tabindex=\"0\">\n      <ul>\n        <li>\n          {{#_if body.link '==' 'Nahrungsergaenzungen'}}\n            <img src=\"/resources/images/navi_banner/nahrungsergaenzung.png\">\n          {{/_if}}\n          {{#_if body.link '==' 'Lebensmittel'}}\n            <img src=\"/resources/images/navi_banner/lebensmittel.png\">\n          {{/_if}}\n          {{#_if body.link '==' 'Koerperpflege'}}\n            <img src=\"/resources/images/navi_banner/koerperpflege.png\">\n          {{/_if}}\n          {{#_if body.link '==' 'Haushalt'}}\n            <img src=\"/resources/images/navi_banner/haushalt.png\">\n          {{/_if}}\n        </li>\n      </ul>\n      <ul>\n        {{{elements}}}\n        <li class=\"ez_header_lv3 ez_header_show_all\">\n          <a class=\"ez_show-more\" href='[component:link]'>\n            [label:show_all]\n          </a>\n        </li>\n      </ul>\n    </div>\n  </li>\n{{/_if}}\n",
                                        "3": "{{#_if head.article_count '>' 0}}\n  <li class=\"nav-item ez_header_lv3\">\n      <a class=\"{{#if active}}active{{/if}}\" href='[component:link]'>\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    </a>\n  </li>\n{{/_if}}\n"
                                    },
                                    "rows": {
                                        "1": "<li class=\"ez_header_lv1{{#_if head.position '==' '1'}} ez_first_item{{/_if}}\">\n  <a class='btn btn-main{{#if active}} active{{/if}}' href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n  </a>\n</li>\n",
                                        "2": "<li class='ez_header_lv2'>\n  <a class=\"{{#if active}}active{{/if}}\" href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n  </a>\n</li>\n",
                                        "3": "{{#_if body.static_name '==' '#Spaltentrenner'}}\n  </ul>\n  <ul class='spaltentrenner'>\n{{else}}\n  <li class='ez_header_lv3'>\n    {{#contains body.static_name \"#\"}}\n      <span class=\"subline\">{{#cut body.static_name '#'}}{{/cut}}</span>\n    {{else}}\n      <a class=\"{{#if active}}active{{/if}}\" href='[component:link]'>\n        {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      </a>\n    {{/contains}}\n  </li>\n{{/_if}}\n",
                                        "4": "<li class='ez_header_lv4'>\n  <a class=\"{{#if active}}active{{/if}}\" href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n  </a>\n</li>\n"
                                    }
                                },
                                "header_navi_small": {
                                    "main_container": "<div class=\"nav-item accordion-item\">\n  <a class=\"btn btn-main nav-link mx-0\" href='/'>\n    [label:home]\n  </a>\n</div>\n{{{elements}}}\n",
                                    "containers": {
                                        "1": "<div class='nav-item accordion-item'>\n  <button class=\"btn btn-main accordion-header collapsed mx-0\" id='{{{body.id}}}' type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapsable-{{{body.id}}}\" aria-expanded=\"false\" aria-controls=\"collapsable-{{{body.id}}}\">\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      <svg class=\"ez_icon chev_contract\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/minus-solid.svg#minus_solid\"></use>\n      </svg>\n      <svg class=\"ez_icon chev_expand\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/plus-solid.svg#plus_solid\"></use>\n      </svg>\n  </button>\n  <div class='ez_navbar_small_dropdown accordion-collapse collapse w-100 drop-lv2' id=\"collapsable-{{{body.id}}}\">\n    <div class=\"accordion-body\">\n      {{{elements}}}\n    </div>\n  </div>\n</div>\n",
                                        "2": "{{#_if head.article_count '>' 0}}\n  <div class='nav-item accordion-item'>\n    <button class=\"btn btn-main accordion-header collapsed mx-0 {{{body.data}}}\" id='{{{body.id}}}' type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapsable-{{{body.id}}}\" aria-expanded=\"false\" aria-controls=\"collapsable-{{{body.id}}}\">\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      <svg class=\"ez_icon chev_contract\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/minus-solid.svg#minus_solid\"></use>\n      </svg>\n      <svg class=\"ez_icon chev_expand\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/plus-solid.svg#plus_solid\"></use>\n      </svg>\n    </button>\n    <div class='ez_navbar_small_dropdown accordion-collapse collapse w-100 drop-lv3' id=\"collapsable-{{{body.id}}}\">\n      <div class=\"accordion-body\">\n        {{{elements}}}\n      </div>\n    </div>\n  </div>\n{{/_if}}\n",
                                        "3": "{{#_if head.article_count '>' 0}}\n  <div class='nav-item accordion-item'>\n    <button class=\"btn btn-main accordion-header collapsed mx-0 {{{body.data}}}\" id='{{{body.id}}}' type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapsable-{{{body.id}}}\" aria-expanded=\"false\" aria-controls=\"collapsable-{{{body.id}}}\">\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      <svg class=\"ez_icon chev_contract\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/minus-solid.svg#minus_solid\"></use>\n      </svg>\n      <svg class=\"ez_icon chev_expand\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/plus-solid.svg#plus_solid\"></use>\n      </svg>\n    </button>\n    <div class='ez_navbar_small_dropdown accordion-collapse collapse w-100 drop-lv3' id=\"collapsable-{{{body.id}}}\">\n      <div class=\"accordion-body\">\n        <div class=\"nav-item accordion-item level-1\">\n          <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n            {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}} | [label:allproducts]\n          </a>\n        </div>\n        {{{elements}}}\n      </div>\n    </div>\n  </div>\n{{/_if}}\n"
                                    },
                                    "rows": {
                                        "1": "<div class='nav-item accordion-item level-1{{#if active}} active{{/if}}'>\n  <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n  </a>\n</div>\n",
                                        "2": "{{#_if head.article_count '>' 0}}\n  <div class='nav-item accordion-item level-1{{#if active}} active{{/if}}'>\n    <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    </a>\n  </div>\n{{/_if}}\n",
                                        "3": "{{#_if head.article_count '>' 0}}\n  <div class='nav-item accordion-item level-1{{#if active}} active{{/if}}'>\n    <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    </a>\n  </div>\n{{/_if}}\n",
                                        "4": "{{#_if head.article_count '>' 0}}\n  <div class='nav-item accordion-item level-1{{#if active}} active{{/if}}'>\n    <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n      {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    </a>\n  </div>\n{{/_if}}\n"
                                    }
                                },
                                "side_nav": {
                                    "main_container": "{{{elements}}}\n",
                                    "containers": {
                                        "1": "<div class='nav-item accordion-item{{#if active}} selected{{/if}}{{#_if head.position '==' '1'}} mt-0{{/_if}}'>\n  <button class=\"btn btn-main accordion-header mx-0{{#if active}} active{{else}} collapsed{{/if}}\" id='{{{body.id}}}' type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapsable-side-{{{body.id}}}\" aria-expanded=\"false\" aria-controls=\"collapsable-side-{{{body.id}}}\">\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      <svg class=\"ez_icon chev_contract\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/minus-solid.svg#minus_solid\"></use>\n      </svg>\n      <svg class=\"ez_icon chev_expand\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/plus-solid.svg#plus_solid\"></use>\n      </svg>\n  </button>\n  <div class='ez_navbar_small_dropdown accordion-collapse collapse w-100 drop-lv2 {{#if active}} show{{/if}}' id=\"collapsable-side-{{{body.id}}}\">\n    <div class=\"accordion-body{{#if active}} selected{{/if}}\">\n      <div class='nav-item accordion-item level-1'>\n        <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n          [label:show_all]\n          <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n            <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n          </svg>\n        </a>\n      </div>\n      {{{elements}}}\n    </div>\n  </div>\n</div>\n",
                                        "2": "<div class='nav-item accordion-item{{#if active}} selected{{/if}}'>\n  <button class=\"btn btn-main accordion-header mx-0{{#if active}} active{{else}} collapsed{{/if}}\" id='{{{body.id}}}' type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapsable-side-{{{body.id}}}\" aria-expanded=\"false\" aria-controls=\"collapsable-side-{{{body.id}}}\">\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      <svg class=\"ez_icon chev_contract\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/minus-solid.svg#minus_solid\"></use>\n      </svg>\n      <svg class=\"ez_icon chev_expand\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/plus-solid.svg#plus_solid\"></use>\n      </svg>\n  </button>\n  <div class='ez_navbar_small_dropdown accordion-collapse collapse w-100 drop-lv3 {{#if active}} show{{/if}}' id=\"collapsable-side-{{{body.id}}}\">\n    <div class=\"accordion-body{{#if active}} selected{{/if}}\">\n      <div class='nav-item accordion-item level-1 d-none'>\n        <a class=\"btn btn-main nav-link mx-0\" id='{{{body.id}}}' href='[component:link]'>\n          [label:show_all]\n          <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n            <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n          </svg>\n        </a>\n      </div>\n      {{{elements}}}\n    </div>\n  </div>\n</div>\n",
                                        "3": "<div class='nav-item accordion-item{{#if active}} selected{{/if}}'>\n  <button class=\"btn btn-main accordion-header mx-0{{#if active}} active{{else}} collapsed{{/if}}\" id='{{{body.id}}}' type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#collapsable-side-{{{body.id}}}\" aria-expanded=\"false\" aria-controls=\"collapsable-side-{{{body.id}}}\">\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n      <svg class=\"ez_icon chev_contract\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/minus-solid.svg#minus_solid\"></use>\n      </svg>\n      <svg class=\"ez_icon chev_expand\" width=\"14\" height=\"14\" fill=\"currentColor\">\n        <use xlink:href=\"/resources/images/icons/plus-solid.svg#plus_solid\"></use>\n      </svg>\n  </button>\n  <div class='ez_navbar_small_dropdown accordion-collapse collapse w-100 drop-lv4 {{#if active}} show{{/if}}' id=\"collapsable-side-{{{body.id}}}\">\n    <div class=\"accordion-body{{#if active}} selected{{/if}}\">\n      <div class='nav-item accordion-item level-1{{#if active}} selected{{/if}} d-none'>\n        <a class=\"btn btn-main nav-link mx-0{{#if active}} active{{/if}}\" id='{{{body.id}}}' href='[component:link]'>\n          [label:show_all]\n          <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n            <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n          </svg>\n        </a>\n      </div>\n      {{{elements}}}\n    </div>\n  </div>\n</div>\n"
                                    },
                                    "rows": {
                                        "1": "<div class='nav-item accordion-item level-1{{#if active}} selected{{/if}}'>\n  <a class=\"btn btn-main nav-link mx-0{{#if active}} active{{/if}}\" id='{{{body.id}}}' href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n      <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n    </svg>\n  </a>\n</div>\n",
                                        "2": "<div class='nav-item accordion-item level-2{{#if active}} selected{{/if}}'>\n  <a class=\"btn btn-main nav-link mx-0{{#if active}} active{{/if}}\" id='{{{body.id}}}' href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n      <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n    </svg>\n  </a>\n</div>\n",
                                        "3": "<div class='nav-item accordion-item level-3{{#if active}} selected{{/if}}'>\n  <a class=\"btn btn-main nav-link mx-0{{#if active}} active{{/if}}\" id='{{{body.id}}}' href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n      <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n    </svg>\n  </a>\n</div>\n",
                                        "4": "<div class='nav-item accordion-item level-4{{#if active}} selected{{/if}}'>\n  <a class=\"btn btn-main nav-link mx-0{{#if active}} active{{/if}}\" id='{{{body.id}}}' href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n    <svg class=\"bi ez_searchlens\" width=\"18\" height=\"18\" fill=\"currentColor\">\n      <use xlink:href=\"/resources/images/icons/chevron-right.svg#chevron_right\"/>\n    </svg>\n  </a>\n</div>\n"
                                    }
                                },
                                "small_nav": {
                                    "main_container": "{{{elements}}}\n",
                                    "containers": {
                                        "1": "{{{elements}}}\n",
                                        "2": "<li class=\"block\">\n  <a class=\"nav_item\" href=\"/list/{{#cut curr_item_link '1_Supplementa'}}{{/cut}}\">\n    {{#cut body.static_name '1_'}}{{/cut}}\n  </a>\n  {{#if active}}\n    <ul class=\"menu horizontal nested no_list_style\">{{{elements}}}</ul>\n  {{/if}}\n</li>\n"
                                    },
                                    "rows": {
                                        "1": "<li class=\"block\">\n  <a class=\"nav_item\" href=\"/list/{{#cut curr_item_link '1_Supplementa'}}{{/cut}}\">\n    {{#cut body.static_name '1_'}}{{/cut}}\n  </a>\n</li>\n",
                                        "2": "[component:templates.small_nav.rows.1]"
                                    }
                                },
                                "breadcrumbs": {
                                    "main_container": "{{{elements}}}",
                                    "containers": {
                                        "1": "{{#_ifCond body.static_name '!=' 'Shop' }}{{#if active}}<span class='breadcrumb_item'><span class='breadcrumb_spacer'>»</span><a href='[component:link]'>{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}</a></span>{{/if}}{{/_ifCond}}{{{elements}}}",
                                        "2": "[component:templates.breadcrumbs.containers.1]",
                                        "3": "[component:templates.breadcrumbs.containers.1]"
                                    },
                                    "rows": {
                                        "1": "{{#if active}}<span class='breadcrumb_item'><span class='breadcrumb_spacer'>»</span><a href='[component:link]'>{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}</a></span>{{/if}}",
                                        "2": "[component:templates.breadcrumbs.rows.1]",
                                        "3": "[component:templates.breadcrumbs.rows.1]",
                                        "4": "[component:templates.breadcrumbs.rows.1]"
                                    }
                                },
                                "headline": {
                                    "main_container": "<span class='listing_headline_name_text'>{{{elements}}}</span>",
                                    "containers": {
                                        "1": "{{#if active}}{{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}}{{/if}}{{{elements}}}"
                                    },
                                    "rows": {
                                        "1": "{{#if active}}{{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}}{{/if}}",
                                        "2": "{{#if active}}&nbsp;-&nbsp;{{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}}{{/if}}"
                                    }
                                },
                                "title_nav": {
                                    "main_container": "{{{elements}}}",
                                    "containers": {
                                        "1": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n  {{{elements}}}\n{{/if}}\n",
                                        "2": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n  {{{elements}}}\n{{/if}}\n",
                                        "3": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n  {{{elements}}}\n{{/if}}\n"
                                    },
                                    "rows": {
                                        "1": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n{{/if}}\n",
                                        "2": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n{{/if}}\n",
                                        "3": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n{{/if}}\n",
                                        "4": "{{#if active}}\n  {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}, \n{{/if}}\n"
                                    }
                                },
                                "header_image": {
                                    "main_container": "{{{elements}}}",
                                    "containers": {
                                        "1": "{{#if active}}{{#_if elements '==' ''}}<img src='{{{body.images.header_large}}}' alt=''>{{/_if}}{{{elements}}}{{/if}}"
                                    },
                                    "rows": {
                                        "1": "{{#if active}}<img src='{{{body.images.header_large}}}' alt=''>{{/if}}",
                                        "2": "{{#if active}}<img src='{{{body.images.header_large}}}' alt=''>{{/if}}"
                                    }
                                },
                                "second_level": {
                                    "main_container": "{{{elements}}}",
                                    "containers": {
                                        "1": "{{#_if curr_link '==' curr_item_link}}\n  {{{elements}}}\n{{/_if}}\n",
                                        "2": "{{#starts_with curr_item_link curr_link}}\n  <div class='og_nav'>\n    <div class='navi'>\n      <a href='/[label:list]/{{{curr_item_link}}}'>\n        <h3>{{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}}</h3>\n      </a>\n      <p>\n        {{{elements}}}\n      </p>\n    </div>\n    <a href='/[label:list]/{{{curr_item_link}}}'>\n      <img src='{{{body.images.kachel_large}}}' alt=''>\n    </a>\n  </div>\n{{/starts_with}}\n"
                                    },
                                    "rows": {
                                        "2": "<div class='og_nav'>\n  <div class='navi'>\n    <a href='/[label:list]/{{{curr_item_link}}}'>\n      <h3>{{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}}</h3>\n    </a>\n  </div>\n  <a href='/[label:list]/{{{curr_item_link}}}'>\n    <img src='{{{body.images.kachel_large}}}' alt=''>\n  </a>\n</div>\n",
                                        "3": "{{#_if count '==' 4}}\n  <a href='/[label:list]/{{#cut_after_last curr_item_link '/'}}{{/cut_after_last}}'>\n    <i>Weitere anzeigen</i>\n  </a>\n{{else}}\n  {{#_if count '<' 4}}\n    <a href='/[label:list]/{{{curr_item_link}}}'>\n      {{{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}}\n    </a><br>\n  {{/_if}}\n{{/_if}}\n"
                                    }
                                },
                                "filters_nav": {
                                    "main_container": "{{{elements}}}\n",
                                    "containers": {
                                        "1": "{{#if active}}\n  {{{elements}}}\n{{/if}}\n",
                                        "2": "{{#if active}}\n  {{{elements}}}\n{{/if}}\n",
                                        "3": "{{#if active}}\n  <div class=\"dropdown filters\">\n    <button class=\"btn dropdown-toggle\" type=\"button\" id=\"ez_main_filter_button\" data-bs-toggle=\"dropdown\" aria-expanded=\"false\">\n      <i class=\"fas fa-sliders-h\">&nbsp;&nbsp;&nbsp;</i>Produktfilter\n    </button>\n    <div class=\"dropdown-menu\" aria-labelledby=\"ez_main_filter_button\">\n      {{{elements}}}\n    </div>\n  </div>\n{{/if}}\n"
                                    },
                                    "rows": {
                                        "1": "",
                                        "2": "",
                                        "3": "",
                                        "4": "{{#contains body.static_name \"#\"}}\n  <span class=\"dropdown-item subline\">\n    {{#cut body.static_name '#'}}{{/cut}}\n  </span>\n{{else}}\n  <a class=\"dropdown-item {{#if active}}selected{{/if}}\" href='[component:link]'>\n    {{#cut body.static_name 'HER_,992_,9921_,9922_,999_'}}{{/cut}}\n  </a>\n{{/contains}}\n"
                                    }
                                }
                            }
                        }
                    },
                    "config": {
                        "root_folder": "json_files",
                        "hasMouseOverEvent": false,
                        "hasClickEvent": true,
                        "shopName": "supplementab2c"
                    },
                    "labels": {
                        "de": {
                            "list": "list",
                            "static": "static",
                            "show_all": "Alle anzeigen",
                            "home": "Startseite",
                            "allproducts": "alle Produkte"
                        },
                        "en": {
                            "list": "en/list",
                            "static": "en/static",
                            "show_all": "Show all",
                            "home": "Home",
                            "allproducts": "all products"
                        }
                    }
                }
            }
        };
        this.template = "foundation";
        this.language = new Pair(1, "de");
        this.flagAllowCookies = true;
        this.globalConfigurations = new Array();
        this.modules = new Array();
        if ( !this.configuration ) return false;

        this.modulesConfiguration = this.configuration.modules;
        this.globalConfigurations = this.configuration.global;

        if ( this.modulesConfiguration == null ) return false;
        var languageID: any = "1";

        var languageCode: string = this.getGlobalConfig( "language_mapping." + "1" );
        if ( languageID && languageCode ) {
            this.language = new Pair(languageID, languageCode);
        }
        return true;
    }

    /** @Chris
     * Added async/await functionality so lower part (see the next @Chris tag)
     * @returns void
     */
    public async init():Promise<void> {
        Helpers.init();
        Globals.init();
        this.includeModules();
    }

    // Modules will be loaded, if the html-element has the attribute: data-ez-module-[Modulename]
    // So please ensure, that all modules will have the data-ez-module-[Modulename] html attribute.
    private async includeModules(): Promise<void> {
        const moduleName: string = "Menu";
        const moduleElement = document.querySelector("[data-ez-module-" + "menu" + "]" ); // Find the module in the html-site
        if ( moduleElement ) {
            let modulesConfiguration: any = Json.getSubobject( this.modulesConfiguration, moduleName );
            if ( modulesConfiguration !== null && modulesConfiguration.type === undefined ) {
                try {
                    let module: Module<Model> = new ModuleMenu(modulesConfiguration);
                    this.modules.push( module );
                    this.initModule( module, false);
                }
                catch (e) {
                    console.log( "Das folgende Modul existiert nicht oder konnte nicht aufgerufen werden: " + moduleName, false );
                    console.log( "Pfad: src/modules/" + moduleName + "/Module" + moduleName + ".ts", false );
                }
            }
        }
    }

    public initModules(): void {
        for ( let i = 0; i < this.modules.length; i++ ) {
            this.initModule( this.modules[i], false);
        }
    }

    public initModule( module: Module<Model>, reInit: boolean = false ): void {

        if ( ( !(module.readyToRun()) && !reInit ) || !module.isActive ) return;

        if ( !( !this.flagAllowCookies && module.useCookies() ) ) {
            try {
                if ( reInit ) module.runAllInitializedControllers();
                else module.runWithPreCheck();

                if ( !module.foundError() ) {
                    module.callGlobalModuleEventFunction( "loaded" );
                    module.setExecutionStatus( ModuleExecutionStatus.SUCCESSFULL );
                } else {
                    module.setExecutionStatus( ModuleExecutionStatus.CATCHED_ERROR );
                }
            } catch (error) {
                module.setInitializationCompleted();
                module.setExecutionStatus( ModuleExecutionStatus.NOT_CATCHED_ERROR );
            }
        } else {
            module.setExecutionStatus( ModuleExecutionStatus.COOKIES );
        }
    }

    // Ruft eine Methode des Modules auf
    public callMethod(modulename: string, methodname: string, ...args: any[]): any {
        var result: any = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == modulename) {
                result = this.modules[i].callMethod(methodname, args);

                break;
            }
        }
        return result;
    }

    public parseTextToJson( text:string ): Object {
        let result: Object = null;
        try {
            result = JSON.parse( text );
        } catch(error) {
            console.error(error);
        }
        return result;
    }

    public getGlobalConfig(key:string): any {
        return Json.getSubobject( this.globalConfigurations, key );
    }

    // Globale Funktionen
    public findModule(name: string): Module<Model> {// -> Zeigt ein Objekt mit allen Infos des Modules an
        var module: Module<Model> = null;
        for (let i = 0; i < this.modules.length; i++) {
            if (this.modules[i].getName() == name) {
                module = this.modules[i];
                break;
            }
        }
        return module;
    }

    public getModule( name: string, id: number ): Module<Model> {
        if ( Module.checkControllerAccess( name, id ) ) {
            return this.findModule( name );
        } else {
            return null;
        }
    }

    // Getter
    public getLanguageCode(): string {
        return this.language.getValue();
    }

    public getLanguageID(): number {
        return this.language.getKey();
    }

    public getTemplate(): string {
        return this.template;
    }

    public getKontaktKey(): string {
        return this.sKontaktKEY;
    }

    public getKontaktID(): string {
        return this.sKontaktID;
    }

    public getTickcount(): string {
        return this.sTICKCOUNT;
    }

    private isDefinedVar( sKontaktID: string, sKontaktKEY: string, sTICKCOUNT: string ): Boolean {
        if ( sKontaktID && sKontaktKEY && sTICKCOUNT ) {
            return true;
        }
        return false;
    }
}