import { Modules } from "./Modules";

export var modules: Modules = new Modules();
const t = performance.now();
let boolo = false;
declare var window: any;
document.addEventListener("DOMContentLoaded", async function() {
    try {
        await boolVal();
        console.log( performance.now() - t);
    }
    catch(err) { console.log(err); }
});

const boolVal = async ()=> {
    boolo = await modules.checkRequirements();
    if ( boolo ) await modules.init();
}
window.ezmodules = modules;