import { HTMLModule } from "../../classes/mvc/HTML/HTMLModule";
import { ControllerMenu } from "./ControllerMenu";
import { ModelMenu } from "./ModelMenu";

export class ModuleMenu extends HTMLModule<ModelMenu> {

  //  private tempHTMLController:ControllerMenu=new ControllerMenu( this.getName(), this.getAccessID(), null);

    public constructor( configuration: Object ) {
        super( "Menu", configuration );
       // console.log(this.tempHTMLController);
        //this.initOne( ControllerMenu.prototype);
        this.registerDependentModule( "Menu", ControllerMenu.prototype );
        this.initAll( ControllerMenu.prototype );
      
    }

    public run(): void {
        this.runAllControllers();
    }

    public onControllersInitialized(): void {}

}