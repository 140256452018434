import { HTMLController } from "./HTMLController";
import { Model } from "../Model";
import { Module } from "../Module";
import { Controller } from "../Controller";
import { Globals } from "../../Globals";
import { Logging } from "../../logging/Logging";

export abstract class HTMLModule<ModelType extends Model> extends Module<ModelType> {
    public constructor( name: string, configuration: Object, flagUseCookies: boolean = false ) {
        super( name, configuration, flagUseCookies );
    }

    public reInit( prototype: HTMLController<ModelType> = null ): void {
        if ( typeof prototype !== undefined && prototype != null ) {
            this.initAll( prototype );
            this.runAllControllers();
        }
    }

    /**
     * 
     * Init all modules
     */
    public initAll( prototype: HTMLController<ModelType> ): void {
        let elements: any = this.getAllNotInitializedModuleElements();

        for ( let i = 0; i < elements.length; i++ ) {
            this.init( prototype, jQuery(elements[i]) );
        }
    }

    public initOne( prototype: HTMLController<ModelType> ): void {
        let elements: any = this.getAllNotInitializedModuleElements();

        if ( elements.length > 0 ) {
            this.init( prototype, jQuery( elements[0] ) );
        }
    }

    public findController( id: string ): Controller<ModelType> {
        let result: Controller<ModelType> = null;

        for ( let i = 0; i < this.getControllers().length; i++ ) {
            if ( this.getControllers()[i].getID() == id ) {
                result = this.getControllers()[i];

                break;
            }
        }

        return result;
    }

    private getAllNotInitializedModuleElements(): any {
        return jQuery( "[" + Globals.MODULE_ATTRIBUTE_KEY + this.getName().toLowerCase() +"]:not(["+ Globals.MODULE_INITIALIZED +"])" );
    }

    private init( prototype: HTMLController<ModelType>, element: JQuery<HTMLElement> ): HTMLController<ModelType> {
        let result: HTMLController<ModelType> = null;

        if ( prototype instanceof HTMLController ) {
            let instance: HTMLController<ModelType> = this.createControllerInstance<HTMLController<ModelType>>( prototype, this.getName(), this.getAccessID(), element );

            if ( instance != null ) {
                result = instance;
                this.addController( instance );

                element.attr( Globals.MODULE_INITIALIZED, "" );
            }
        }

        return result;
    }

    private createControllerInstance<T>( prototype: HTMLController<ModelType>, accessName: string, accessID: number, element: JQuery<HTMLElement> ): T {
        try {
            let instance = Object.create( prototype );

            let obj=new instance.constructor( accessName, accessID, element );

            return <T>obj;
        } catch (error) {
            Logging.errorMes( "Das folgende Module konnte nicht Instanziiert werden: " + accessName );
            return null;
        }
    }

    public static findControllerID( element: JQuery<HTMLElement>, moduleName: string ): string {
        let result: string = "";

        let moduleElement: JQuery<HTMLElement> = element.find( "[" + Globals.MODULE_ATTRIBUTE_KEY + moduleName.toLowerCase() + "]" );
        if ( moduleName.length ) {
            result = this.getControllerID( moduleElement );
        }

        return result;
    }

    public static getControllerID( element: JQuery<HTMLElement> ): string {
        var result: string = "";

        if ( element.length ) {
            var controllerID: string = element.attr( Globals.MODULE_ID_KEY );

            if ( controllerID !== undefined ) {
                result = controllerID;
            }
        }

        return result;
    }
}