import { ModelMenu } from "./ModelMenu";
import { modules } from "../../main";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";

export class ControllerMenu extends HTMLController<ModelMenu> {

	private static MENU_FILE: Object;
	private static FULL_SEO_LINK: string;
	// data-configurations
	private position: string; // data-ez-param-pos=[main_navi|header_navi|footer_navi]
	private templatename: string;   // data-ez-param-template=[side_nav|header_navi]
	private template: any;  // YAML-Datei: modules/Menu/components/foundation/template/$this.templatename --> assign the template for the menu from the YAML-File to process. (Template consists of the following nodes: container, rows)
	private fallback: string; // TODO: Check if we need this option any more.
	private entryPoint: string; // TODO: Check if we need this option any more.
	private baseLinkPath: string;
	private useAllItems: boolean; // data-ez-param-use-all=["true"|"false"]
	private isInSideNav: boolean = false;
	private isInMainNav: boolean = false;
	private isInHeaderNav: boolean = false;

	// Global YAML Configurations
	private menuFileBasePath: string; // Menu-File-Basepath is the base-path for the menu.json file to load. YAML-Location: /modules/menu/config/root_folder

	public constructor ( accessName: string, accessID: number, element: JQuery<HTMLElement> ){
		super( new ModelMenu(), accessName, accessID, element );
	}

	public initGlobals ():void{
		ControllerMenu.FULL_SEO_LINK = '';

		let rootFolder = this.getModule().getConfig("root_folder");
		rootFolder = ( rootFolder == null ? "": rootFolder );

		// Build the menu file base path
		this.menuFileBasePath = window.location.origin;
		if ( rootFolder != "" ){
			this.menuFileBasePath += "/" + rootFolder; // test.domain.de/rootFolder/menu-de.json
		} else {
			this.menuFileBasePath += "/menu"; // URL: test.domain.de/menu/menu-de.json
		}
		this.menuFileBasePath += "/menu-";
		// Get the menu file
		ControllerMenu.MENU_FILE = this.getModel().getMenuFile( this.menuFileBasePath );
    }

	public run():void{
		if ( ControllerMenu.MENU_FILE == null ) { // Is a menu-json-file defined?
			console.log(" keine der folgenden Menüdateien geladen werden konnte: " + this.menuFileBasePath + "[sprachID z.B 1].json oder" + this.menuFileBasePath + "[sprachCode z.B. de].json" );
			return;
		}
		// Get the defined template for the menu from html-data-attributes
		this.templatename = this.getParam( "template" );

		switch( this.templatename ) {
			case "side_nav":
				this.isInSideNav = true;
				break;
			case "main_nav":
				this.isInMainNav = true;
				break;
			case "header_nav":
				this.isInHeaderNav = true;
				break;
		}

		if ( this.templatename === null ) {
			console.log( "kein Template angegeben wurde" );
			return;
		}

		// load all configurations for the menu from data-attributes + yaml setup
		this.template = this.getModule().getComponent( "templates." + this.templatename );
		this.position = this.getParam( "pos" );
		this.fallback = this.getParam( "fallback" ); //TODO: Check if fallback will be needed anymore
		this.entryPoint = this.getParam( "entry" ); // TODO: Check if we need this option any more.
		this.baseLinkPath = "";
		this.useAllItems = this.getParam( "use-all" ) == "true";

		if ( this.position == null ) this.position = "";
		if ( this.fallback == null ) this.fallback = "";
		if ( this.entryPoint == null ) this.entryPoint = "";
		if ( this.useAllItems == null ) this.useAllItems = false;

		if ( typeof ControllerMenu.FULL_SEO_LINK === "undefined" ) {
			console.log( " folgende Variable fehlt: ezentrum_variables.full_seo_link" );
			return;
		}
		if ( this.template == null ) {
			console.log( "Das folgende Template nicht gefunden wurde: " + this.templatename );
			return;
		}
		//Set the entry point
		let items:Array<Object> = (ControllerMenu.MENU_FILE  as any).elements;
		if ( this.entryPoint != "" ) {
			let entry:Array<Object> = this.findEntryPoint( items, this.entryPoint );
			if( entry != null ){
				this.baseLinkPath = this.entryPoint;
				items = entry;
			}
		}
		// Start processing
		this.outputElements( this.processAll( items ) );
		// Anpassungen nach der Erstellung des HTML
		this.mouseClickEvent();
	}

	// Works also without bootstrap targeting
	private mouseClickEvent(): void {
		const nav_container: HTMLUListElement = document.getElementById('ez-header-navi') as HTMLUListElement;
		const collapseElementList = nav_container.querySelectorAll('[data-bs-toggle="collapse"]');
		let timer: any;

		collapseElementList.forEach(ele => {
			ele.addEventListener('mouseover', () => {
				clearTimeout(timer);
			});

			ele.addEventListener('mouseout', () => {
				timer = setTimeout(() => {
					collapseElementList.forEach((innerEle: any) => {
						if (innerEle.getAttribute('aria-expanded') === 'true') {
							innerEle.click();
						}
					});
				}, 1500);
			});

			// Add mouseover and mouseout event listeners corresponding list
			const collapseItem = document.querySelector(`${ele.getAttribute('data-bs-target')}`);
			if ( collapseItem ) {
				collapseItem.addEventListener('mouseover', ()=> {
					clearTimeout(timer);
				});
				collapseItem.addEventListener('mouseout', ()=> {
					timer = setTimeout(() => {
						collapseElementList.forEach((innerEle: any) => {
							if (innerEle.getAttribute('aria-expanded') === 'true') {
								innerEle.click();
							}
						});
					}, 1500);
				});
			}
		});
	}

	private findEntryPoint( items:Array<Object>, path:string ): Array<Object> {
		let result: Array<Object> = null;
		let pathItems: Array<string> = path.split( "/" );
		for (let i = 0; i < pathItems.length; i++) {
			for (let j = 0; j < items.length; j++) {
				if( (items[j] as any).body.link == pathItems[i] ){
					if( i == pathItems.length - 1 ){
						result = (items[j] as any).sub_elements;
					} else {
						result = this.findEntryPoint( (items[j] as any).sub_elements, this.removeByIndex( pathItems, i ).join("/") );
					}
					break;
				}
			}
			break;
		}
		return result;
	}

	// Method: outputElements
	// Generates from one html-element for the resulting html-string
	private outputElements ( content:string ):void{
		let element:JQuery<HTMLElement> = content != "" ? jQuery( content ) : jQuery( this.fallback );
		if( element.length ){
			if ( !this.setOutputElement( element ) ){
				this.getElement().append( element );
			}
		}
	}

	// Method: processAll
	// Central method processes the entries of each level
	private processAll ( elements:Array<Object> ):string{
		let result = null
		if ( elements != null ) {
			this.getModule().addView( "main_container", this.template.main_container  );
			result = this.processOne( "main_container", "elements", this.processLevel( elements ) );
			this.getModule().clearViews();
		}
		return result;
	}

	// Method: processLevel
	// Central method processes the entries of each level
	private processLevel ( elements:Array<Object>, lastlink:string = "", is_subelement:boolean = false ):string{
		let result:string = "";
		if ( elements != null ) {

			// Loop through the items
			for (let i = 0; i < elements.length; i++) {
				let modelID = this.getModel().new();
				let last_link = lastlink;
				if ( !is_subelement ) { last_link = ""; }
				let element = elements[i];
				let allowed_positions:Array<string> = (element as any).head.positions;

				if ((allowed_positions.indexOf( this.position ) >= 0) || (this.useAllItems))
				{
					// Local Processing Vars
					let currentFullLink:string = "";
					let current_template:string = "";
					let level:number = (element as any).head.level;
					let sbid:number = (element as any).body.sbid;
					let link:string = (element as any).body.link;
					let all_subelements:Array<Object> = (element as any).sub_elements;
					let allowed_subelements:Array<Object> = new Array(); // Array of allowed navigation-sub-element items
					let display_node_without_hits:boolean=false; // ZART-Mode Display without hits status

					if( this.useAllItems ){ // Process all entries from level 1, ignore if sub-elements have 0 positions. 
						allowed_subelements = all_subelements;
						display_node_without_hits=true;
					}
					else  // Process all entries from level 1 and display only entries that have more than 0 entries. 
					{
						// Note: If all entries for the sub-elements have 0 hits, then the main node for example on level-1 will not be displayed.
						// Please consider this for the navigation and if a main node will not be displayed, then please check, whether all sub-items have 0 positions.
						// If you want to display the main entry even if all sub-elements have 0 positions, then activate this.useALLItems.

						// Get all allowed sub items
						for (let j = 0; j < all_subelements.length; j++) {
							let tmp_allowed_positions:Array<string> = (all_subelements[j] as any).head.positions;
							if ( tmp_allowed_positions.indexOf( this.position ) >= 0 ){
								allowed_subelements.push( all_subelements[j] );
							}
						}
					}

					if ( allowed_subelements.length > 0 ) 	// Process item with sub elements
					{
						last_link += ( last_link == "" ? "" : "/" ) + link;
						let currentContainer:string = this.template.containers[level];
						if ( currentContainer != null ){
							current_template = "current_container_" + level;

							this.getModule().addView( current_template, currentContainer );
							this.getModel().add( modelID, "elements", this.processLevel( allowed_subelements, last_link, true ) );
						}
						currentFullLink = last_link;
					} else {
						let currentRow:string = (this.template.rows[level] );
						if ( currentRow != null ){
							current_template = "current_row_" + level;
							this.getModule().addView( current_template, currentRow );
						}
						currentFullLink = last_link + ( last_link == "" ? "" : "/" ) + link;
					}

					if ( this.baseLinkPath != "" ){
						currentFullLink = this.baseLinkPath + "/" + currentFullLink;
					}

					if ( current_template != "" ) {// Is a temmplate defined in the yaml file
						// Check if the current item is active
						let sameValueCount:number = 0;
						let fullSEOLinkItems:Array<string> = ControllerMenu.FULL_SEO_LINK.split( "/" );
						let currentFullLinkItems:Array<string> = currentFullLink.split( "/" );

						for (let j = 0; j < currentFullLinkItems.length; j++) {
							if ( fullSEOLinkItems[j] !== undefined ){
								if ( fullSEOLinkItems[j] == currentFullLinkItems[j] ){
									if (currentFullLinkItems[j]!=="")
										sameValueCount++;
								}
							}
						}

						let active:boolean = (sameValueCount == currentFullLinkItems.length);
						/**  All all global items to the model */
						this.getModel().add( modelID, "head",(element as any).head );
						this.getModel().add( modelID, "body", (element as any).body );
						this.getModel().add( modelID, "active", active );
						let display:boolean= true // Central Method for display checking. Implementation "display" in YAML configuration.
						if (display_node_without_hits) // Only set in SPEA + ZART Mode - Global State
							display=true;
						this.getModel().add( modelID, "display", display );
						this.getModel().add( modelID, "isInSideNav", this.isInSideNav );
						this.getModel().add( modelID, "isInMainNav", this.isInMainNav );
						this.getModel().add( modelID, "children", allowed_subelements.length );
						this.getModel().add( modelID, "first_item", i == 0);
						this.getModel().add( modelID, "last_item", i == elements.length - 1);
						this.getModel().add( modelID, "count", i);
						this.getModel().add( modelID, "curr_link", ControllerMenu.FULL_SEO_LINK );
						this.getModel().add( modelID, "language", modules.getLanguageCode());

						if ( sbid == 0 ){
							this.getModel().add( modelID, "curr_item_link", link );
						} else {
							this.getModel().add( modelID, "curr_item_link", currentFullLink );
						}

						/** Process Handlebars */
						result += this.process( modelID, current_template, true );
					}
				}
			}
		}
		return result;
	}
	private removeByIndex<T> ( array: Array<T>, index: number ): Array<T> {
        if ( index > -1 && index < array.length ) {
            array.splice( index, 1 );
        }
        return array;
    }
}