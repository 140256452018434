import { Model } from "../../classes/mvc/Model";
import { AjaxRequest } from "../../libs/AjaxRequest";
import { modules } from "../../main";

export class ModelMenu extends Model {
    public getMenuFile( menuFileBasePath: string ): Object {
        let result = AjaxRequest.getJson( menuFileBasePath + modules.getLanguageID() + ".json" );

        if ( result == null ) {
            result = AjaxRequest.getJson( menuFileBasePath + modules.getLanguageCode() + ".json" );
        }

        return result;
    }
}