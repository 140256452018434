import { Arrays } from "../../libs/Arrays";
import { Pair } from "../../libs/Pair";

export class Model {

    private static CURRENT_ID: number = 0; // Counter for unique view generation
    private variables: Array<Pair<number, any>>; // Storage for all views for the model.

    // ***********************************************************
    // Constructor: Model
    // Initialization the storage for the views for the Model. 
    // ***********************************************************
    public constructor() {
        this.variables = new Array();
    }

    // ***********************************************************
    // Method: handlebarsExport(viewID)
    // Description: 
    // Returns a data object identified by id from the view storage.
    // The view will be needed for handlebar processing.
    // ***********************************************************
    public handlebarsExport ( id: number ): Object {
        let result: Object = {};
        for ( let i = 0; i < this.variables.length; i++ ) {
            if ( this.variables[i].getKey() == id ) {
                result = this.variables[i].getValue();
            }
        }
        return result;
    }

    // ***********************************************************
    // Method: clear (viewID) 
    // Description: 
    // Removes the view identified by id from the current model.
    // ***********************************************************
    public clear ( id:number ): void {
        for ( let i = 0; i < this.variables.length; i++ ) {
            if ( this.variables[i].getKey() == id ) {
                this.variables = Arrays.removeByIndex( this.variables, i );
            }
        }
    }

    // ***********************************************************
    // Method: new()
    // Description: 
    // Increments the current view id counter.
    // Add the view to the central model view storage.
    // Return: The new view-id for the model.
    // ***********************************************************

    public new(): number {
        Model.CURRENT_ID++;
        this.variables.push( new Pair( Model.CURRENT_ID, {} ) );
        return Model.CURRENT_ID;
    }

    // ***********************************************************
    // Method: add(id, key, value)
    // Appends only if the variable does not exists with name=key. If it will be added, then it will be initialized with the value.
    // Parameter: 
    // -id(number): viewID
    // -key(string): variablename for the view. key defines the variable name of the view.
    // -value(any): variablevalue for processing in handlebars.
    // ***********************************************************

    public add ( id: number, key: string, value: any ): void {
        // Loop through the model view storage
        for ( let i = 0; i < this.variables.length; i++ ) {
            if ( this.variables[i].getKey() == id ) // View in storage matches the "id"
            {
                // Get all keys=variables from the view entry in the storage
                var keys: Array<string> = Object.keys( this.variables[i].getValue() );
                if ( keys.indexOf( key ) < 0 ) // key does not exist, then add the "key" and the "value" to the view.
                {
                    this.variables[i].getValue()[ key ] = value;
                }
            }
        }
    }
}